<template>
    <div>
        <div class="card-toolbar mb-5">
            <router-link v-if="$can('purchase_orders.create')" to="/purchases/purchases-orders/create" class="btn btn-primary font-weight-bolder">
                <v-icon>mdi-plus</v-icon>
                {{ $t('purchases_orders.add_purchases_order')}}
            </router-link>
            <button @click="openAdvancedSearch" class="btn btn-outline-primary font-weight-bolder ml-5">
                <span><i class="la la-filter"></i> <span>{{$t('advanced_filter')}}</span></span>
            </button>
        </div>

        <div class="card card-custom mb-5" v-if="showAdvancedSearch">
            <div class="card-body">
                <div class="m-form m-form--fit m--margin-bottom-20">
                    <div class="row mb-5">
                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="supplier_id">{{$t('purchases_orders.supplier')}}</label>
                            <!-- <select name="" id="supplier_id" v-model="filters.supplier_id" class="custom-select" >
                                <option v-for="row in suppliers" :value="row.id" :key="row.id">
                                    {{ row.full_name }}
                                </option>
                            </select> -->
                            <multiselect v-model="supplier"
                                         id="supplier_id"
                                         :placeholder="$t('purchases_orders.supplier')"
                                         label="full_name"
                                         track-by="id"
                                         :options="suppliers"
                                         :multiple="false"
                                         :taggable="false"
                                         :show-labels="false"
                                         :show-no-options="false"
                                         :show-no-results="false"
                                         @search-change="getSuppliers($event)">
                            </multiselect>
                        </div>
                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="purchase_code">{{$t('purchases_orders.purchase_code')}}</label>
                            <input v-model="filters.purchase_code" type="text" id="purchase_code" class="form-control">
                        </div>
                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="from_date">{{$t('purchases_orders.from_date')}}</label>
                            <input v-model="filters.from_date" type="date" id="from_date" class="form-control">
                        </div>
                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="to_date">{{$t('purchases_orders.to_date')}}</label>
                            <input v-model="filters.to_date" type="date" id="to_date" class="form-control">
                        </div>
                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label>{{$t('purchases_orders.currency')}}</label>
                            <select name="" id="currency_id" v-model="filters.currency_id" class="custom-select">
                                <option v-for="row in currencies" :value="row.id" :key="row.id">
                                    {{ row.name }}
                                </option>
                            </select>
                        </div>
                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label>{{$t('purchases_orders.added_by')}}</label>
                            <!-- <select name="" id="added_by" v-model="filters.user_id" class="custom-select" >
                                <option v-for="row in users" :value="row.id" :key="row.id">
                                    {{ row.name }}
                                </option>
                            </select> -->
                            <multiselect v-model="user"
                                         :placeholder="$t('purchases_orders.added_by')"
                                         label="name"
                                         track-by="id"
                                         :options="users"
                                         :multiple="false"
                                         :taggable="false"
                                         :show-labels="false"
                                         :show-no-options="false"
                                         :show-no-results="false"
                                         @search-change="getUsers($event)">
                            </multiselect>
                        </div>
                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="f_status">{{$t('status')}}</label>
                            <select name="" id="f_status" v-model="filters.status" type="text" class="custom-select">
                                <option value="">{{$t('all')}}</option>
                                <option v-for="(row, index) in status_list" :value="row.id" :key="index">{{ row.title }}</option>
                            </select>
                        </div>


                        <div class="form-group d-inline-flex col-md-6 mt-10">
                            <button type="button" @click="doFilter" class="btn btn-primary mb-auto mr-2" id="m_search">
                            <span><i class="fas fa-search"></i>
                                <span>{{$t('search')}}</span>
                            </span>
                            </button>
                            <button type="button" @click="resetFilter" class="btn btn-danger mb-auto mr-2" id="m_reset">
                            <span><i class="fas fa-trash-restore"></i>
                                <span>{{$t('reset_search')}}</span>
                            </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--begin::customer-->
        <div class="card card-custom">

            <div class="card-body">

                <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="table">

                    <template slot="status" slot-scope="props">

                        <button v-if="$can('purchase_orders.change_status')" @click="changeStatus(props.row.id, props.row.status)" class="btn btn-secondary m-btn m-btn--icon w-auto">
                            {{$t('change_status')}} ({{ props.row.status_name }})
                        </button>

                        <button v-else class="btn btn-secondary m-btn m-btn--icon w-auto">
                            {{$t('change_status')}} ({{ props.row.status_name }})
                        </button>
                    </template>
                    <template slot="actions" slot-scope="props">

                        <v-btn v-b-tooltip.hover :title="$t('edit')" v-if="$can('purchase_orders.update')" icon color="pink" :to="`/purchases/purchases-orders/edit/${props.row.id}`">
                            <v-icon small class="mr-2 text-info">mdi-pencil</v-icon>
                        </v-btn>

                        <v-icon small class="text-danger" v-b-tooltip.hover :title="$t('delete')" v-if="$can('purchase_orders.delete')" @click="deleteItem(props.row)">mdi-delete</v-icon>
                        <v-btn v-b-tooltip.hover :title="$t('view')" icon color="pink" :to="`/purchases/purchases-orders/view/${props.row.id}`">
                            <v-icon small class="mr-2">mdi-eye</v-icon>
                        </v-btn>

                        <b-dropdown id="dropdown-offset" :text="$t('more_actions') " variant="outline-primary" class="m-2">
                            <router-link v-if="$can('purchase_orders.printing_export_pdf')" target="_blank" class="dropdown-item" :to="`/print/purchase-order/${props.row.id}/print`">
                                {{ $t('MENU.invoice_printing') }}
                            </router-link>
                            <router-link v-if="$can('purchase_orders.printing_export_pdf')" target="_blank" class="dropdown-item" :to="`/print/purchase-order/${props.row.id}/pdf`">
                                {{ $t('export_invoice_pdf') }}
                            </router-link>
                            <a v-if="props.row.status == 2 && $can('purchase_orders.convert_to_purchase_invoice')" href="javascript:;" :class="{'dropdown-item': true, 'disabled': (props.row.purchase_invoice_id ? true : false)}" @click="props.row.purchase_invoice_id ? false : actionConvertToPurchaseInvoice(props.row.id)">
                                {{ $t('purchases_orders.convert_to_purchase_invoice') }}
                            </a>
                        </b-dropdown>
                    </template>
                </v-server-table>

            </div>
        </div>
        <!--end::customer-->
        <b-modal ref="status-modal" hide-footer :title="$t('change_status')">
            <change-status-form @hide-modal="hideModal()"
                                :status-list="status_list"
                                :url="routeChangeStatus"
                                :current-id="innerId"
                                :current-status="statusId"
                                @handling-data="getDataAfterChangeStatus">
            </change-status-form>
        </b-modal>
    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";
    import ChangeStatusFrom from "@/view/content/forms/ChangeStatusForm";

    export default {
        name: "index-purchases-orders",
        components: {'change-status-form': ChangeStatusFrom,},
        data() {
            return {
                mainRoute: 'purchases/purchase-orders',
                routeChangeStatus: 'purchases/purchase-order/change-status',
                mainRouteDependency: 'base/dependency',
                routeConvertToPurchaseInvoice: 'purchases/purchase-order/convert-to-purchase-invoice',

                showAdvancedSearch: false,

                filter: {
                    sortBy: 'id',
                },
                filters: {
                    supplier_id: null,
                    purchase_code: null,
                    from_date: null,
                    to_date: null,
                    currency_id: null,
                    user_id: null,
                    status: null,
                },
                status_list: [],
                columns: ['create_date', 'supplier_name', 'purchase_date', 'purchase_code', 'currency_name', 'status', 'actions'],
                data: [],
                currencies: [],
                suppliers: [],
                users: [],
                user: null,
                supplier: null,
                innerId: null,
                statusId: null,

            }
        },
        watch: {
            user: function (val) {
                if (val) {
                    this.filters.user_id = val.id;
                } else {
                    this.filters.user_id = null;
                }
            },
            supplier: function (val) {
                if (val) {
                    this.filters.supplier_id = val.id;
                } else {
                    this.filters.supplier_id = null;
                }
            },
        },
        computed: {
            options: function () {
                let that = this;
                return {
                    texts: {
                        loadingError: that.$t('Something_went_wrong'),
                        filter: "",
                        filterBy: that.$t('Filter') + ' {column}',
                        count: ' ',
                        filterPlaceholder: that.$t('Search_query'),
                        loading: that.$t('Loading') + "...",
                    },
                    headings: {
                        create_date: that.$t('purchases_orders.create_date'),
                        supplier_name: that.$t('purchases_orders.supplier'),
                        purchase_date: that.$t('purchases_orders.purchase_date'),
                        purchase_code: that.$t('purchases_orders.purchase_code'),
                        currency_name: that.$t('purchases_orders.currency'),
                        status: that.$t('status'),
                        actions: that.$t('actions'),
                    },
                    sortable: ['name'],
                    filterByColumn: false,
                    filterable: false,
                    customFilters: [{
                        name: 'alphabet',

                    }],
                    orderBy: {'column': 'id'},

                    alwaysShowPerPageSelect: false,
                    perPage: 10,
                    pagination: {chunk: 5, dropdown: false},
                    skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
                    sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
                    perPageValues: [5, 10, 15, 25],

                    requestFunction(data) {
                        let _params = {
                            ascending: data.ascending,
                            byColumn: data.byColumn,
                            limit: data.perPage,
                            orderBy: data.orderBy,
                            page: data.page,
                            filter: data.query,
                            ...that.filters,
                        }
                        return ApiService.query(`${that.mainRoute}`, {..._params});

                    },
                    responseAdapter(resp) {
                        return {
                            data: resp.data.data.data,
                            count: resp.data.data.total,
                        }
                    }
                }

            },
        },
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.purchases_orders")}]);
            this.getCurrencies();


            this.getStatusList();
        },
        methods: {
            // changeStatus(id, status) {
            //     ApiService.patch(this.routeChangeStatus + '/' + id, {
            //         status: (status ? 1 : 0),
            //     }).then((response) => {
            //         this.$refs.table.refresh();
            //         this.$successAlert(response.data.message);
            //     }).catch(error => {
            //         this.$errorAlert(error);
            //     });
            // },
            openAdvancedSearch() {
                this.showAdvancedSearch = !this.showAdvancedSearch;
            },
            doFilter() {
                this.$refs.table.refresh();
            },
            resetFilter() {
                this.filters.supplier_id = null;
                this.filters.purchase_code = null;
                this.filters.from_date = null;
                this.filters.to_date = null;
                this.filters.currency_id = null;
                this.filters.user_id = null;
                this.filters.status = null;

                this.user = null;
                this.supplier = null;


                this.$refs.table.refresh();
            },
            getFetch() {
                this.$refs.table.refresh();
            },

            deleteItem(item) {
                this.$confirmAlert('', this.actionDelete, item);
            },
            getCurrencies() {
                ApiService.get(this.mainRouteDependency + "/currencies").then((response) => {
                    this.currencies = response.data.data;
                });
            },
            getSuppliers(filter) {
                if(filter && filter.length >= 3)
                    ApiService.get(this.mainRouteDependency + "/suppliers", {params:{filter: filter}}).then((response) => {
                        this.suppliers = response.data.data;
                    });
            },
            getUsers(filter) {
                if(filter && filter.length >= 3)
                    ApiService.get(this.mainRouteDependency +"/users", {params:{filter: filter}}).then((response) => {
                        this.users = response.data.data;
                    });
            },
            getStatusList() {
                ApiService.get(this.mainRouteDependency + "/status").then((response) => {
                    this.status_list = response.data.data;
                });
            },
            actionDelete(item) {
                ApiService.delete(`${this.mainRoute}/${item.id}`).then((response) => {
                    this.getFetch();
                    this.$successAlert(response.data.message)
                }).catch((error) => {
                    this.$errorAlert(error);
                })
            },

            changeStatus(id, status) {
                this.innerId = id;
                this.statusId = status;
                this.showModal();
            },
            showModal() {
                this.$refs['status-modal'].show()
            },
            hideModal() {
                this.$refs['status-modal'].hide();
            },
            getDataAfterChangeStatus() {
                this.innerId = null;
                this.statusId = null;
                this.doFilter();
            },
            actionConvertToPurchaseInvoice(id) {
                this.$confirmAlert(this.$t('purchases_orders.do_you_want_convert_to_purchase_invoice'), this.convertToPurchaseInvoice, id);
            },
            convertToPurchaseInvoice(id){
                ApiService.patch(this.routeConvertToPurchaseInvoice + '/' + id).then((response) => {
                            this.$refs.table.refresh();
                            this.$successAlert(response.data.message);
                        }).catch(error => {
                            this.$errorAlert(error);
                        });
            },

        },
    };
</script>
